import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchModels(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/research', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchModel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/research/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateModel(ctx, { modelData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/research/${id}`, modelData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteModel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/research/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addModel(ctx, modelData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/research', modelData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchAllFeatures(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-type/all-features')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEvaluationRequestsTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/config/evaluationRequests.types')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCitiesByRegion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/region/${id}/cities`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRegions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/region/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistricts(ctx, { id }) {
      console.log(id);
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContractOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
